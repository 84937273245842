import React from "react";
import { Routes, Route } from "react-router-dom";
import { Home } from "../Pages/Home";
import { AboutUs } from "../Pages/AboutUs";
import { ContactUs } from "../Pages/ContactUs";
import { Products } from "../Pages/Products";
import { PrivacyPolicies } from "../Pages/PrivacyPolicies";

export function Paths() {
  return (
    <Routes>
      <Route exact path={"/"} element={<Home />} />
      <Route exact path={"/sobre-nos"} element={<AboutUs />} />
      <Route exact path={"/produtos"} element={<Products />} />
      <Route exact path={"/contatos"} element={<ContactUs />} />
      <Route exact path={"/privacy-policies"} element={<PrivacyPolicies />} />
    </Routes>
  );
}
