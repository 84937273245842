import styled from "styled-components";

export const Main = styled.div`
  height: 90vh;
  background: linear-gradient(rgba(255,255,255,.8), rgba(19,80,112,.9));


  .titleAccordion {
    font-family: "Montserrat", sans-serif;
    font-size: 20px;
    font-weight: 700;
  }

  p {
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    font-weight: 300;
    line-height: 18.29px;
  }

  #panel1bh-header .MuiSvgIcon-root,
  #panel2bh-header .MuiSvgIcon-root,
  #panel3bh-header .MuiSvgIcon-root,
  #panel4bh-header .MuiSvgIcon-root {
    color: #fff;
  }

  @media (max-width: 1024px) {
    .titleAccordion {
      font-size: 18px;
    }
    p {
      font-size: 14px;
    }
  }

  @media (min-width: 768px) and (max-width: 991px) {
    .accordionContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-right: 60px;
    }

    .titleAccordion {
      font-size: 16px;
    }
    p {
      font-size: 12px;
    }
  }

  @media (min-width: 481px) and (max-width: 767px) {
    .accordionContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-self: center;
      margin: 0 70px;
    }

    .titleAccordion {
      font-size: 16px;
    }
    p {
      font-size: 12px;
    }
  }

  @media (max-width: 480px) {
    .accordionContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-self: center;
      width: 70%;
      margin: 0 0 0 40px;
    }

    .titleAccordion {
      font-size: 14px;
    }
    p {
      font-size: 12px;
    }
  }
`;

export const ImgContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: end;
`;

export const AnimationGif = styled.img`
  width: auto;
  height: 600px;
  /* margin-left: 60px; */
  margin-top: 50px;
  transition: fill .3s ease;
  /* mix-blend-mode: multiply; */

  @media (max-width: 1199px) {
    width: auto;
    height: 500px;
  }

  @media (min-width: 481px) and (max-width: 767px) {
    width: auto;
    height: 400px;
    position: absolute;
    bottom: 0;
  }

  @media (min-width: 381px) and (max-width: 480px) {
    height: auto;
    position: absolute;
    bottom: 0;
  }

  @media (max-width: 380px) {
    height: 450px;
    position: absolute;
    bottom: 0;
  }
`;

export const CranioImg = styled.img`
  width: auto;
  height: 556px;
  position: fixed;
  right: 0;
  top: 10%;
  mix-blend-mode: multiply;
  transition: fill .3s ease;

  @media (min-width: 768px) and (max-width: 991px) {
    width: auto;
    height: 480px;
  }

  @media (min-width: 481px) and (max-width: 767px) {
    width: auto;
    height: 380px;
  }

  @media (max-width: 480px) {
    opacity: 60%;
    width: 350px;
    height: 480px;
    margin-left: 50px;
    object-fit: contain;
    display: none;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  justify-content: right;
  align-content: center;
  max-width: 670px;
  flex-wrap: wrap;
  gap: 25px;
  text-align: justify;
  margin: 15px;
  transition: fill .3s ease;

  h1 {
    color: #135070;
    font-size: 18px;
  }

  p {
    font-size: 15px;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    text-align: left;
    max-width: 550px;
  }

  @media (max-width: 500px) {
    max-width: 300px;
    justify-content: center;
    align-self: center;
    margin-left: 60px;
  }

  @media (max-width: 390px) {
    max-width: 280px;
    justify-content: center;
    align-self: center;
    margin-left: 50px;
  }
`;

export const TextAboutUs = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 15px;
`;

export const MissionValuesVisionContainer = styled.div`
  display: flex;
  align-items: start;
  gap: 20px;

  @media (min-width: 992px) and (max-width: 1099px) {
    flex-wrap: wrap;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    flex-wrap: wrap;
  }
`;

export const TextMission = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 15px;
`;

export const TextVision = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 15px;
`;

export const TextValue = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 15px;
`;

export const Section = styled.section`
  display: flex;
  flex-direction: row;
  height: 90vh;
  justify-content: center;

  @media (max-width: 500px) {
    flex-direction: column-reverse;
  }

  @media (max-width: 768px) {
    justify-content: center;
  }
`;
