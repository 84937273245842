import styled from "styled-components";

export const Main = styled.div`
  height: 90vh;
  transition: fill .3s ease;
  background: linear-gradient(rgba(255,255,255,.8), rgba(19,80,112,.9));

  h1 {
    font-size: 20px;
    color: #232ed1;
  }
  
  h2 {
    color: #fff;
  }

  p {
    font-size: 15px;
    font-weight: 300;
    line-height: 18.29px;
  }
`;

export const ImgContainer = styled.div`
  display: flex;
  align-items: flex-end;
`;

export const CranioImg = styled.img`
  width: auto;
  height: 556px;
  right: 0;
  mix-blend-mode: multiply;
  transition: fill .3s ease;

  @media (min-width: 1024px) and (max-width: 1299px) {
    width: auto;
    height: 430px;
  }

  @media (max-width: 1023px) {
    display: none;
    width: 380px;
    height: 480px;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  width: 100vw;
  margin-left: 220px;
  transition: fill .3s ease;

  h1,
  span {
    font-size: 70px;
    font-weight: 700;
    color: #1ac8ed;
    transition: fill .3s ease;
  }

  h2 {
    font-size: 24px;
    font-weight: 700;
    padding-left: 5px;
    transition: fill .3s ease;
  }

  @media (min-width: 1024px) and (max-width: 1399px) {
    margin-left: 120px;

    h1,
    span {
      font-size: 60px;
    }

    h2 {
      font-size: 20px;
    }
  }

  @media (min-width: 768px) and (max-width: 1023px) {
    width: 30%;
    margin: 60px 0 20px 0;

    h1,
    span {
      font-size: 40px;
    }

    h2 {
      font-size: 14px;
    }
  }

  @media (min-width: 550px) and (max-width: 767px) {
    width: 35%;
    margin: 60px 0 20px 0;

    h1,
    span {
      font-size: 40px;
    }

    h2 {
      font-size: 14px;
    }
  }
  @media (min-width: 400px) and (max-width: 549px) {
    width: 300px;
    margin: 80px 0 20px 0;

    h1,
    span {
      font-size: 40px;
    }

    h2 {
      font-size: 14px;
    }
  }

  @media (max-width: 399px) {
    width: 260px;
    margin: 80px 0 30px 0;

    h1,
    span {
      font-size: 40px;
    }

    h2 {
      font-size: 14px;
    }
  }
`;

export const TextChamadaEscolar = styled.div`
  /* height: 90vh; */
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  transition: fill .3s ease;

  /* @media (max-width: 1024px) {
    width: 250px;
    top: 85px;
  }
  @media (max-width: 768px) {
    right: 160px;
    top: 170px;
    width: 280px;
  }

  @media (min-width: 378px) and (max-width: 425px) {
    right: -350px;
    top: 200px;
    width: 550px;
  }

  @media (min-width: 0px) and (max-width: 375px) {
    right: -280px;
    top: 200px;
    width: 550px;
  } */
`;

export const Section = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 90vh;
  max-width: 100vw;

  @media (min-width: 769px) and (max-width: 1023px) {
    flex-direction: column;
    justify-content: center;
  }

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: center;
  }
`;
