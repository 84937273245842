import React from "react";
import {
  Main,
  ImgContainer,
  CranioImg,
  TextContainer,
  TextChamadaEscolar,
  Section,
} from "./style.module";
import { Header } from "../../Components/Header";
import { Footer } from "../../Components/Footer";
import { BarSocialMedias } from "../../Components/BarSocialMedias";
import Cranios from "../../assets/cranio_sem_fundo.png";
import { Formulario } from "../../Components/FormClient";

export const ContactUs = () => {
  return (
    <Main>
      <Header />
      <BarSocialMedias />
      <Section>
        <TextContainer>
          <h1>Sua face</h1>
          <span style={{ color: "#135070" }}>é a chave</span>
          <h2>Soluções em reconhecimento facial.</h2>
        </TextContainer>
        <TextChamadaEscolar>
          <Formulario />
        </TextChamadaEscolar>
        <ImgContainer>
          <CranioImg src={Cranios} alt="Exemplo de GIF" />
        </ImgContainer>
      </Section>
      <Footer />
    </Main>
  );
};
