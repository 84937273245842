import styled from "styled-components";

export const Main = styled.footer`
  display: flex;
  justify-content: space-evenly;
  width: 100vw;
  height: 10vh;
  background-color: #135070;
`;

export const LabelFooter = styled.div`
  width: 70%;
  display: flex;
  align-items: center;
  p {
    font-size: 20px !important;
    margin-bottom: 5px;
    padding-right: 15px;
    color: white;
  }
  @media (min-width: 1200px) {
    justify-content: space-around;
    width: 92%;

    button {
      font-size: 16px;
    }
  }

  @media (min-width: 768px) and (max-width: 1199px) {
    width: 92%;

    button {
      font-size: 14px;
    }
  }
  @media (min-width: 481px) and (max-width: 767px) {
    width: 92.5%;
  }
  @media (max-width: 480px) {
    width: 92.5%;
    padding: 10px;
    p {
      margin-right: 15px;
      font-size: 14px !important;
      font-weight: 700;
    }
  }
`;
