import React from "react";
import {
  Main,
  ImgContainer,
  AnimationGif,
  CranioImg,
  ChamadaIMG,
  TextContainer,
  TextChamadaEscolar,
  Section,
} from "./style.module";
import { Header } from "../../Components/Header";
import { Footer } from "../../Components/Footer";
import { BarSocialMedias } from "../../Components/BarSocialMedias";
import { WhatsappButton } from "../../Components/WhatsappButton";
import Gif from "../../assets/Products.svg";
import Cranios from "../../assets/cranio_sem_fundo.png";
import ChamadaEscolar from "../../assets/ChamadaEscolar.jpeg";
// import videoBg from "../../assets/video_background.mp4";

export const Products = () => {
  return (
    <Main>
      <div className="styledVideo">
        <div className="overlay"></div>
        <video src={"https://sasudtfstate.blob.core.windows.net/sudarium-container/escola_moldelo.mp4"} autoPlay muted loop />
        {/* <video src={videoBg} autoPlay muted loop /> */}
      </div>
      <Header />
      <Section>
        <BarSocialMedias />
        <ImgContainer>
          <AnimationGif src={Gif} alt="Exemplo de GIF" />
          <CranioImg src={Cranios} alt="Exemplo de GIF" />
        </ImgContainer>
        <TextContainer>
          <TextChamadaEscolar>
            <div className="alignTitle">
              <ChamadaIMG src={ChamadaEscolar} alt="Exemplo de GIF" />
              <h1>Chamada Segura</h1>
            </div>
            <p>Biometria Facial</p>
            <p>Automação de Presença na Chamada Escolar</p>
            <p>
              Uso da Tecnologia "Liveness Detection" no mapeamento facial 3D
            </p>
            <p>
              Responsáveis informados de horário de entrada e saída em tempo
              real
            </p>
            <p>
              Instituições de Ensino Públicas - evitar desperdício de merenda
              relatórios online
            </p>
          </TextChamadaEscolar>
        </TextContainer>
        <WhatsappButton />
      </Section>
      <Footer />
    </Main>
  );
};
