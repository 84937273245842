import React from "react";
import {
  Main,
  AnimationGif,
  ImgContainer,
  CranioImg,
  TextContainer,
  Section,
} from "./style.module";
import { Header } from "../../Components/Header";
import { Footer } from "../../Components/Footer";
import { BarSocialMedias } from "../../Components/BarSocialMedias";
import { WhatsappButton } from "../../Components/WhatsappButton";
import GifLight from "../../assets/Animacao-1.gif";
import Cranios from "../../assets/cranio_sem_fundo.png";
// import imgLaize from "../../assets/EmpresaImg.svg";

export const Home = () => {
  // const [theme, setTheme] = useState('light');

  // useEffect(() => {
  //   const handleThemeChange = (e) => {
  //     setTheme(e.matches ? 'dark' : 'light')
  //   };

  //   const darkThemeMq = window.matchMedia('(prefers-color-scheme: dark)');

  //   darkThemeMq.addEventListener('change',handleThemeChange);

  //   setTheme(darkThemeMq.matches ? 'dark' : 'light');

  //   return darkThemeMq.removeEventListener('change', handleThemeChange);
  // }, []);

  return (
    <Main>
      <div className="styledVideo">
        <div className="overlay"></div>
        <video src={"https://sasudtfstate.blob.core.windows.net/sudarium-container/video_completo_apresent.mp4"} autoPlay muted loop />
        {/* <video src={videoBg} autoPlay muted loop /> */}
      </div>
      <Header />
        <TextContainer>
          <h1>Sua face</h1>
          <span style={{ color: "#135070" }}>é a chave</span>
          <h2>Soluções em reconhecimento facial.</h2>
        </TextContainer>
      <Section>
        <ImgContainer>
          <AnimationGif src={GifLight} alt="Exemplo de GIF" />
          <CranioImg src={Cranios} alt="Exemplo de GIF" />
        </ImgContainer>
          <WhatsappButton/>
        <BarSocialMedias />
      </Section>
      <Footer />
    </Main>
  );
};
