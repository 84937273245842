import styled from "styled-components";

export const Main = styled.div`
  height: 90vh;

  .styledVideo {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: -1;
    transition: fill 0.3s ease;
  }

  video {
    /* margin-top: 10vh; */
    width: 100%;
    height: 100vh;
    object-fit: cover;
  }

  .overlay {
    position: absolute;
    /* top: 10vh; */
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      rgba(255, 255, 255, 0.8),
      rgba(19, 80, 112, 0.9)
    );
  }

  h1 {
    font-size: 100px;
    font-weight: 700;
    color: #1ac8ed;
    /* color: #232ed1; */
  }

  span {
    font-size: 100px;
    font-weight: 700;
  }

  h2 {
    color: #fff;
    font-size: 24px;
    font-weight: 700;
    padding-left: 5px;
  }

  @media (min-width: 1200px) {
    h1,
    span {
      font-size: 100px;
    }

    h2 {
      font-size: 22px;
    }
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    h1,
    span {
      font-size: 90px;
    }

    h2 {
      font-size: 22px;
    }
  }

  @media (min-width: 768px) and (max-width: 991px) {
    h1,
    span {
      font-size: 80px;
    }

    h2 {
      font-size: 22px;
    }
  }

  @media (min-width: 481px) and (max-width: 767px) {
    h1,
    span {
      font-size: 70px;
    }

    h2 {
      font-size: 18px;
    }
  }
  @media (max-width: 480px) {
    h1,
    span {
      font-size: 50px;
    }

    h2 {
      font-size: 16px;
    }
  }
`;

export const ImgContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 480px) {
    align-items: center;
    justify-content: center;
  }

  @media (min-width: 481px) and (max-width: 767px) {
    justify-content: center;
    align-items: center;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    align-content: end;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    align-content: end;
  }

  @media (min-width: 1200px) and (max-width: 1919px) {
  }
`;

export const AnimationGif = styled.img`
  width: 447px;
  height: 556px;
  /* margin-left: 120px; */
  position: absolute;
  mix-blend-mode: multiply;
  transition: fill 0.3s ease;

  @media (min-width: 1401px) {
    width: auto;
    height: 600px;
    bottom: 10vh;
    margin-left: 170px;
  }

  @media (min-width: 1200px) and (max-width: 1400px) {
    width: auto;
    height: 600px;
    bottom: 10vh;
    margin-left: 70px;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    width: auto;
    height: 580px;
    bottom: 10vh;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    width: auto;
    height: 550px;
    bottom: 10vh;
  }
  @media (min-width: 481px) and (max-width: 767px) {
    width: auto;
    height: 450px;
    margin-left: 0;
    bottom: 10vh;
  }
  @media (max-width: 480px) {
    width: 80%;
    height: auto;
    margin-left: 0px;
    bottom: 10vh;
  }
`;

export const CranioImg = styled.img`
  width: auto;
  height: 556px;
  position: fixed;
  right: 0;
  mix-blend-mode: multiply;
  transition: fill 0.3s ease;

  @media (min-width: 768px) and (max-width: 991px) {
    width: auto;
    height: 480px;
  }

  @media (min-width: 481px) and (max-width: 767px) {
    width: auto;
    height: 380px;
  }

  @media (max-width: 480px) {
    opacity: 60%;
    width: 350px;
    height: 480px;
    margin-left: 50px;
    object-fit: contain;
    display: none;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  top: 140px;
  flex-direction: column;
  position: absolute;
  z-index: 1;
  transition: fill 0.3s ease;

  @media (min-width: 1401px) {
    position: fixed;
    padding-left: 10rem;
    left: 30%;
    top: 30%;
  }
  @media (min-width: 1200px) and (max-width: 1400px) {
    padding-left: 5.5rem;
    left: 30%;
    top: 30%;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    padding-left: 10rem;
    left: 20%;
    top: 30%;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    padding-left: 6.5rem;
  }

  @media (min-width: 481px) and (max-width: 767px) {
    left: 25%;
    top: 20%;
  }

  @media (max-width: 480px) {
    left: 20%;
    top: 15%;
  }
`;

export const Section = styled.section`
  display: flex;
  flex-direction: row;
  height: 90vh;

  @media (max-width: 768px) {
    justify-content: center;
  }
`;
