import React from 'react';
import { Main, SocialMedias } from './style.module';
import Box from '@mui/material/Box';
import Insta from "../../assets/instagram.svg";
import Linkedin from "../../assets/linkedin.svg";
import Youtube from "../../assets/youtube.svg";

export const BarSocialMedias = () => {

    const BoxStyle = {
        maxWidth: 4,
        height: "100px",
        backgroundColor: "#135070",
        // backgroundColor: "#232ED1",
        position: "relative",
        marginLeft: "12px",
        borderRadius: "5px",
    }


    return (
        <Main>
        <Box
            sx={BoxStyle}
        /> 
        {/* <a href="https://www.instagram.com" target='blank'>
            <SocialMedias 
                src={Insta}
                alt="Instagram icon"
            />
        </a> */}
        <a href="https://www.linkedin.com/company/sudarium/" target='blank'>  
            <SocialMedias 
                src={Linkedin}
                alt="LinkedIn icon"
            />
        </a>
        {/* <a href="https://www.youtube.com" target='blank'>
            <SocialMedias 
                src={Youtube}
                alt="Youtube icon"
            />
        </a> */}
        <Box
            sx={BoxStyle}
        /> 
        </Main>
    )
}    