import styled from "styled-components";

export const Main = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;

export const PolicyContainer = styled.div`
  max-width: 900px;
  margin: 10vh 0 5vh 0;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 1px 3px 5px 1px rgba(0, 0, 0, 0.75);
  background-color: #fff;
  z-index: 1;
  transition: fill .3s ease;

  .align-button{
    display: flex;
    justify-content: end;
  }
`;

export const PolicyTitle = styled.h2`
  color: #333;
  font-size: 24px;
  margin-bottom: 15px;

  @media (min-width: 481px) and (max-width: 767px) {
    font-size: 20px;
  }
`;

export const SubPolicyTitle = styled.h3`
  color: #555;
  font-size: 20px;
  margin-bottom: 10px;

  @media (min-width: 481px) and (max-width: 767px) {
    font-size: 18px;
  }
`;

export const Paragraph = styled.p`
  color: #666;
  font-size: 16px;
  margin-bottom: 15px;

  @media (min-width: 481px) and (max-width: 767px) {
    font-size: 14px;
  }
`;

export const ButtonBack = styled.button`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: none;
  box-shadow: 1px 2px 2px 0px rgba(0, 0, 0, 0.75);
  color: #fff;
  background-color: #232ed1;
  display: flex;
  position: absolute;
`;
