import React, { useState, useEffect } from "react";
import { Main, Logo, Navigate, ButtonLabel } from "./style.module";
import { Avatar, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";

export const Header = () => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 768);
  const open = Boolean(anchorEl);

  const clientClick = () => {
    navigate("/login");
  };

  const aboutUsClick = () => {
    navigate("/sobre-nos");
  };

  const contactUsClick = () => {
    navigate("/contatos");
  };

  const productsClick = () => {
    navigate("/produtos");
  };

  const homeClick = () => {
    navigate("/");
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuClick = (path) => {
    navigate(path);
    handleClose();
  };

  const styleButton = {
    color: "#135070",
    // color: "#232ED1",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "17.07px",
  };

  const styleButtonClient = {
    bgcolor: "#135070",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "17.07px",
    borderRadius: "25px",
    height: "40px",
    width: "207px",
    display: "none",
  };

  useEffect(() => {
    const updateMedia = () => {
      setIsLargeScreen(window.innerWidth > 768);
    };
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  return (
    <Main>
        <Logo onClick={homeClick} />
      {isLargeScreen ? (
        <Navigate className="large-screen-nav">
          <Button variant="text" sx={styleButton} onClick={homeClick}>
            Início
          </Button>
          <Button variant="text" sx={styleButton} onClick={aboutUsClick}>
            Empresa
          </Button>
          <Button variant="text" sx={styleButton} onClick={productsClick}>
            Produtos
          </Button>
          <Button variant="text" sx={styleButton} onClick={contactUsClick}>
            Contato
          </Button>
          <ButtonLabel>
            <Button
              variant="contained"
              sx={styleButtonClient}
              onClick={clientClick}
            >
              <Avatar
                sx={{ bgcolor: "transparent", marginLeft: "-10px" }}
                src="/broken-image.jpg"
              />
              Área do Cliente
            </Button>
          </ButtonLabel>
        </Navigate>
      ) : (
        <Navigate className="small-screen-nav">
          <div>
            <Button
              id="basic-button"
              aria-controls={open ? "basic-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              onClick={handleClick}
            >
              <MenuIcon sx={{ fontSize: 50, marginTop: 4, color: "#135070" }} />
            </Button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                "aria-labelledby": "basic-button",
              }}
            >
              <MenuItem
                onClick={() => handleMenuClick("/")}
                sx={{ fontSize: "30px", color: "#135070" }}
              >
                Início
              </MenuItem>{" "}
              {/* Altera o tamanho da fonte do MenuItem */}
              <MenuItem
                onClick={() => handleMenuClick("/sobre-nos")}
                sx={{ fontSize: "30px", color: "#135070" }}
              >
                Empresa
              </MenuItem>
              <MenuItem
                onClick={() => handleMenuClick("/produtos")}
                sx={{ fontSize: "30px", color: "#135070" }}
              >
                Produtos
              </MenuItem>
              <MenuItem
                onClick={() => handleMenuClick("/contatos")}
                sx={{ fontSize: "30px", color: "#135070" }}
              >
                Contato
              </MenuItem>
              <MenuItem
                onClick={() => handleMenuClick("/login")}
                sx={{ fontSize: "30px", display: "none" }}
              >
                Área do Cliente
              </MenuItem>
            </Menu>
          </div>
        </Navigate>
      )}
    </Main>
  );
};
