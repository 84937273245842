import React, { useState, useRef } from "react";
import { Form } from "./style.module";
import {
  Checkbox,
  Button,
  FormControlLabel,
  Typography,
  useTheme,
  TextField,
  useMediaQuery,
} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import emailjs from "@emailjs/browser";
import ReCAPTCHA from "react-google-recaptcha";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

export const Formulario = () => {
  const [nome, setNome] = useState("");
  const [email, setEmail] = useState("");
  const [numeroCelular, setNumeroCelular] = useState("");
  const [mensagem, setMensagem] = useState("");
  const [checkUm, setCheckUm] = useState(false);
  const [checkDois, setCheckDois] = useState(false);
  const [capValue, setCapValue] = useState(null);
  const form = useRef();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const SERVICE_ID = "service_ovaa2wr";
  const TEMPLATE_ID = "template_v20v67c";
  const PUBLIC_KEY = "D_QUUCYrT5BwG3eWH";
  // const SERVICE_ID = "service_435gebw";
  // const TEMPLATE_ID = "template_klz1yjl";
  // const PUBLIC_KEY = "N-5c1Os6164mLIhD4";
  const RECAP_KEY = "6LcgzN0pAAAAAOiMwX7Byxb7lNbw4V6cXtevjnRU";

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log("Nome:", nome);
    // console.log("Número de celular:", numeroCelular);
    // console.log("E-mail:", email);
    // console.log("Checkbox um:", valueCheckUm);
    // console.log("Checkbox dois:", valueCheckDois);
    console.log(form.current.name);

    emailjs.sendForm(SERVICE_ID, TEMPLATE_ID, form.current, PUBLIC_KEY).then(
      (result) => {
        console.log(result.text);
        toast.success("Dados enviados com sucesso!");
      },
      (error) => {
        console.log(error.text);
        toast.error("Algo deu errado! Tente novamente!");
      }
    );
    setNome("");
    setEmail("");
    setNumeroCelular("");
    setMensagem("");
    setCheckDois(false);
    setCheckUm(false);
    setCapValue(false);
  };
  // const valueCheckUm = checkUm ? "true" : "false";
  // console.log(valueCheckDois, valueCheckUm);
  const valueCheckUm = checkUm.toString();
  const valueCheckDois = checkDois.toString();

  return (
    <Form onSubmit={handleSubmit} ref={form}>
      <Grid container spacing={1} margin={0}>
        <Grid xs={16} sm={16} md={16} lg={20}>
          <TextField
            sx={{
              width: "100%",
              backgroundColor: "#f9f9f9",
            }}
            size="small"
            id="outlined-multiline-flexible"
            label="Nome"
            required
            value={nome}
            name="nome"
            onChange={(e) => setNome(e.target.value)}
          />
        </Grid>
        <Grid xs={6} sm={6} md={6} lg={6}>
          <TextField
            sx={{
              width: "100%",
              backgroundColor: "#f9f9f9",
            }}
            size="small"
            id="outlined-multiline-flexible"
            type="email"
            label="Email"
            required
            value={email}
            name="email"
            onChange={(e) => setEmail(e.target.value)}
          />
        </Grid>
        <Grid xs={6} sm={6} md={6} lg={6}>
          <TextField
            style={{
              width: "100%",
              backgroundColor: "#f9f9f9",
            }}
            size="small"
            id="outlined-multiline-flexible"
            type="number"
            label="Telefone"
            placeholder="(xx) xxxxx-xxxx"
            value={numeroCelular}
            name="numeroCelular"
            onChange={(e) => setNumeroCelular(e.target.value)}
          />
        </Grid>
        <Grid xs={16} sm={16} md={16} lg={20}>
          <TextField
            style={{
              width: "100%",
              backgroundColor: "#f9f9f9",
              fontFamily: "Montserrat",
            }}
            // size="small"
            id="standard-multiline-flexible"
            variant="outlined"
            type="text"
            label="Mensagem"
            placeholder="Escreva sua mensagem!"
            multiline
            maxRows={3}
            value={mensagem}
            name="mensagem"
            onChange={(e) => setMensagem(e.target.value)}
          />
        </Grid>
      </Grid>
      <FormControlLabel
        control={
          <Checkbox
            checked={checkUm}
            onChange={(e) => setCheckUm(e.target.checked)}
            name={valueCheckUm}
          />
        }
        label={
          <Typography variant="body2">
            <span
              style={{
                fontSize: "11px",
                fontFamily: "montserrat, sans-serif",
                fontWeight: "400",
              }}
            >
              Aceito receber comunicados por WhatsApp
            </span>
          </Typography>
        }
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={checkDois}
            onChange={(e) => setCheckDois(e.target.checked)}
            name={valueCheckDois}
          />
        }
        label={
          <Typography variant="body2">
            <span
              style={{
                fontSize: "11px",
                fontFamily: "montserrat, sans-serif",
                fontWeight: "400",
              }}
            >
              Aceito receber conteúdos exclusivos da Sudarium
            </span>
          </Typography>
        }
      />
      <span>
        Solicitamos seus dados apenas quando realmente precisamos deles para lhe
        fornecer um serviço. Continue para aceitar e em caso de dúvida acesse
        nossa{" "}
        <span style={{ color: "#e21212", fontWeight: "600" }}>
          <Link to="/privacy-policies" style={{ textDecoration: "none" }}>
            Politica de Privacidade
          </Link>
        </span>
        .
      </span>
      <div
        style={{
          transform: isSmallScreen ? "scale(.77)" : "scale(.90)",
          WebkitTransform: "scale(0.77)",
          transformOrigin: "0 0",
          WebkitTransformOrigin: "0 0",
        }}
      >
        <ReCAPTCHA
          theme="light"
          size="normal"
          sitekey={RECAP_KEY}
          onChange={(value) => setCapValue(value)}
        />
      </div>
      <Button
        disabled={!capValue}
        type="submit"
        variant="contained"
        sx={{
          height: isSmallScreen ? "30px" : "35px",
          backgroundColor: "#1ac8ed",
          // backgroundColor: "#232ED1",
          borderRadius: "20px",
          marginTop: isSmallScreen ? "5px" : "10px",
          textTransform: "none",
          fontSize: isSmallScreen ? ".8rem" : "1rem",
        }}
      >
        Entrar em contato
      </Button>
    </Form>
  );
};
