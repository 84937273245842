import styled from "styled-components";

export const WhatsappImg = styled.img`
  position: relative;
  width: 100px;
  height: 100px;
  transition: fill .3s ease;

  @media (min-width: 426px) and (max-width: 1024px) {
    width: 75px;
    height: 75px;
  }
  @media (max-width: 425px) {
    width: 75px;
    height: 75px;
  }
`;

export const StyledLink = styled.a`
  position: fixed;
  width: 100px;
  height: 100px;
  transition: fill .3s ease;

  @media (min-width: 1200px) {
    top: 75%;
    right: 8%;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    top: 75%;
    right: 8%;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    top: 75%;
    right: 8%;
  }

  @media (min-width: 481px) and (max-width: 767px) {
    width: 75px;
    height: 75px;
    top: 75%;
    right: 8%;
  }

  @media (max-width: 480px) {
    width: 60px;
    height: 60px;
    top: 75%;
    right: 8%;
  }
`;