import React from 'react';
import { Main, LabelFooter } from "./style.module";
import { Button } from "@mui/material";

export const Footer = () => {
  const openWhatsApp = () => {
    window.open("https://wa.me/message/BRSIVURP7B5TF1", "_blank");
  };


  const styleButton = {
    height: "60px",
    width: "250px",
    color: "white",
    bgcolor: "#1ac6ed",
    fontWeight: "600",
    fontSize: "12px",
    textTransform: "Capitalize",
    lineHeight: "18.29px",
    borderRadius: "30px",
    paddingRight: "20px",
    textShadow: "2px 2px 5px #135070",

  };

  return (
    <Main>
      <LabelFooter>
        <p>
          Conheça nosso sistema de Biometria Facial para Escolas: Segurança e
          rapidez
        </p>
        <Button variant="contained" sx={styleButton} onClick={openWhatsApp}>
          Fale com consultor
        </Button>
      </LabelFooter>
    </Main>
  );
};
