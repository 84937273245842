import styled from "styled-components";

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  width: 380px;
  height: auto;
  margin-left: 50px;
  background: #ffffff;
  border-radius: 20px;
  padding: 15px;
  /* box-shadow: 1px 3px 5px 1px rgba(0, 0, 0, 0.75); */
  box-shadow: 1px 3px 5px 1px rgba(19, 80, 112, 0.75);
  transition: fill .3s ease;

  .css-dpjnhs-MuiInputBase-root-MuiOutlinedInput-root, .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input, .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input, #outlined-multiline-flexible-label, #standard-multiline-flexible-label {
    font-family: Montserrat;
    font-size: 15px;
  }

  p,
  h3 {
    font-size: 15px;
  }
  span {
    font-size: 12px;
    margin-bottom: 5px;
  }

  @media (max-width: 399px) {
    width: 250px;
    height: auto;
    margin-left: 50px;
    padding: 10px;
    p,
    h3 {
      font-size: 12px;
    }
    span {
      font-size: 10px;
    }

    button {
      height: 20px;
    }
  }

  @media (min-width: 400px) and (max-width: 480px) {
    width: 280px;
    height: auto;
    p,
    h3 {
      font-size: 13px;
    }
    span {
      font-size: 10px;
    }
  }

  @media (min-width: 481px) and (max-width: 1023px) {
    width: 330px;
    height: auto;

    p,
    h3 {
      font-size: 13px;
    }
    span {
      font-size: 10px;
    }
  }

  @media (min-width: 1024px) and (max-width: 1198px) {
    width: 300px;
    height: auto;

    p,
    h3 {
      font-size: 13px;
    }
    span {
      font-size: 10px;
    }
  }
`;
