import styled from "styled-components";
import logo from "../../assets/LogoHome.svg";

export const Main = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  transition: fill .3s ease;
  width: 100vw;
  height: 10vh;
  z-index: 99;

  @media (min-width: 1200px) and (max-width: 1919px) {
    justify-content: center;
  }

  @media (min-width: 768px) and (max-width: 991px) {
  }

  @media (max-width: 767px) {
    justify-content: space-evenly;
  }
`;

export const Logo = styled.div`
  height: 102px;
  width: 322px;
  margin-top: 30px;
  /* margin-left: 150px; */
  cursor: pointer;
  background-image: url(${logo});
  background-size: cover;

  @media (min-width: 1024px) and (max-width: 1919px){
    margin-left: 60px;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    margin: 20px 4px 0 100px;
    margin-left: 60px;
  }

  @media (min-width: 481px) and (max-width: 767px) {
    margin: 50px -4px 0 40px;
  }
  @media (max-width: 480px) {
    margin: 50px -4px 0 -20px;
    width: 300px;
  }
`;
export const Navigate = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: fill .3s ease;
  font-family: "Montserrat", sans-serif;

  @media (min-width: 1200px) and (max-width: 1919px) {
    padding-left: 3rem;
    justify-content: space-evenly;
    width: 50vw;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    justify-content: space-around;
    width: 60vw;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    justify-content: space-around;
    width: 60vw;
  }
`

export const ButtonLabel = styled.div`
  visibility: hidden;
  display: false;
  margin-left: 50px;
  @media (max-width: 1024px) {
    margin-left: 15px;
    margin-right: 15px;
  }

  @media (max-width: 480px) {
    justify-content: start;
  }
`;
