import React from "react";
import { WhatsappImg, StyledLink } from "./style.module";
import Whatsapp from "../../assets/WhatsappLogo-1.gif";

export const WhatsappButton = () => {
  return (
    <StyledLink href="https://wa.me/message/BRSIVURP7B5TF1">
      <WhatsappImg src={Whatsapp} alt="Exemplo de GIF" />
    </StyledLink>
  );
}
