import React from "react";
import { Header } from "../../Components/Header";
import {
  Main,
  PolicyContainer,
  PolicyTitle,
  SubPolicyTitle,
  Paragraph,
  ButtonBack,
} from "./style.module";
import { ImgContainer, CranioImg } from "../Home/style.module";
import { AnimationGif } from "../Products/style.module";
import { Footer } from "../../Components/Footer";
import Cranios from "../../assets/cranio_sem_fundo.png";
import Gif from "../../assets/Products.svg";
import { useNavigate } from "react-router-dom";

export const PrivacyPolicies = () => {
  const navigate = useNavigate();

  const ImgStyled = {
    position: "fixed",
    left: "0",
    bottom: "0",
    height: "450px",
    zIndex: "0",
    mixBlendMode: "multiply",
  };

  const contactUsClick = () => {
    navigate("/contatos");
  };

  return (
    <div>
      <Header />
      <Main>
        <AnimationGif src={Gif} style={ImgStyled} alt="Exemplo de GIF" />
        <PolicyContainer>
          <div className="align-button">
            <ButtonBack onClick={contactUsClick}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="28px"
                viewBox="0 0 24 24"
                width="20px"
                fill="#e8eaed"
              >
                <path d="M0 0h24v24H0V0z" fill="none" />
                <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z" />
              </svg>
            </ButtonBack>
          </div>
          <PolicyTitle>Política de Privacidade</PolicyTitle>

          <PolicyTitle>1. Coleta de Dados</PolicyTitle>
          <SubPolicyTitle>1.1 Dados Pessoais:</SubPolicyTitle>
          <Paragraph>
            A Sudarium Tecnologia Digital coleta dados pessoais e jurídicos
            estritamente necessários para fornecer seus serviços, incluindo
            nome, endereço, informações de contato e documentos jurídicos
            relevantes.
          </Paragraph>
          <SubPolicyTitle>1.2 Biometria Facial:</SubPolicyTitle>
          <Paragraph>
            A Sudarium Tecnologia Digital coleta e processa dados biométricos
            faciais apenas com o consentimento explícito do usuário, para fins
            de autenticação e segurança.
          </Paragraph>

          <PolicyTitle>2. Uso e Finalidade dos Dados</PolicyTitle>
          <SubPolicyTitle>2.1 Dados Pessoais:</SubPolicyTitle>
          <Paragraph>
            Os dados pessoais coletados são utilizados para fornecer e melhorar
            nossos serviços, bem como para cumprir obrigações legais e
            regulatórias.
          </Paragraph>
          <SubPolicyTitle>2.2 Biometria Facial:</SubPolicyTitle>
          <Paragraph>
            Os dados biométricos faciais são utilizados exclusivamente para
            autenticação de identidade e segurança de acesso, e não são
            compartilhados com terceiros sem o consentimento expresso do
            usuário.
          </Paragraph>

          <PolicyTitle>3. Armazenamento e Segurança dos Dados</PolicyTitle>
          <SubPolicyTitle>3.1</SubPolicyTitle>
          <Paragraph>
            A Sudarium Tecnologia Digital implementa medidas técnicas e
            organizacionais adequadas para proteger os dados pessoais e
            biométricos contra acesso não autorizado, uso indevido, alteração ou
            destruição.
          </Paragraph>
          <SubPolicyTitle>3.2</SubPolicyTitle>
          <Paragraph>
            Os dados são armazenados em servidores seguros e são acessados
            apenas por pessoal autorizado, sujeito a obrigações de
            confidencialidade.
          </Paragraph>

          <PolicyTitle>4. Compartilhamento de Dados</PolicyTitle>
          <SubPolicyTitle>4.1</SubPolicyTitle>
          <Paragraph>
            A Sudarium Tecnologia Digital pode compartilhar dados pessoais e
            biométricos com prestadores de serviços terceirizados,
            exclusivamente para os fins descritos nesta política e mediante a
            celebração de acordos de confidencialidade.
          </Paragraph>

          <PolicyTitle>5. Direitos dos Titulares dos Dados</PolicyTitle>
          <SubPolicyTitle>5.1</SubPolicyTitle>
          <Paragraph>
            Os usuários têm o direito de acessar, corrigir, atualizar ou excluir
            seus dados pessoais, bem como de revogar o consentimento para o
            processamento de dados biométricos faciais, mediante solicitação à
            Sudarium Tecnologia Digital.
          </Paragraph>

          <PolicyTitle>6. Alterações na Política de Privacidade</PolicyTitle>
          <SubPolicyTitle>6.1</SubPolicyTitle>
          <Paragraph>
            Esta política de privacidade pode ser atualizada periodicamente para
            refletir mudanças em nossas práticas de privacidade. As alterações
            serão publicadas em nosso site e os usuários serão notificados
            conforme exigido por lei.
          </Paragraph>

          <PolicyTitle>7. Contato</PolicyTitle>
          <SubPolicyTitle>7.1</SubPolicyTitle>
          <Paragraph>
            Se você tiver dúvidas ou preocupações sobre esta política de
            privacidade ou sobre a forma como a Sudarium Tecnologia Digital
            trata seus dados, entre em contato conosco através dos canais
            fornecidos em nosso site.
          </Paragraph>
        </PolicyContainer>
        <ImgContainer>
          <CranioImg src={Cranios} alt="Exemplo de GIF" />
        </ImgContainer>
      </Main>
      <Footer />
    </div>
  );
};
