import React from "react";
import {
  Main,
  AnimationGif,
  ImgContainer,
  CranioImg,
  TextContainer,
  TextAboutUs,
  MissionValuesVisionContainer,
  TextMission,
  TextVision,
  TextValue,
  Section,
} from "./style.module";
import { Header } from "../../Components/Header";
import { Footer } from "../../Components/Footer";
import { BarSocialMedias } from "../../Components/BarSocialMedias";
import { WhatsappButton } from "../../Components/WhatsappButton";
import Gif from "../../assets/EmpresaImg.svg";
import Cranios from "../../assets/cranio_sem_fundo.png";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export const AboutUs = () => {
  const [expanded, setExpanded] = React.useState(false);
  const [isLargeScreen, setIsLargeScreen] = React.useState(
    window.innerWidth > 900
  );

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  React.useEffect(() => {
    const updateMedia = () => {
      setIsLargeScreen(window.innerWidth > 900);
    };
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  return (
    <Main>
      <Header />
      <Section>
        <BarSocialMedias />
        <ImgContainer>
          <AnimationGif src={Gif} alt="Exemplo de GIF" />
          <CranioImg src={Cranios} alt="Exemplo de GIF" />
        </ImgContainer>
        {isLargeScreen ? (
          <TextContainer>
            <TextAboutUs>
              <h1>Quem somos</h1>
              <p>
                A Sudarium é uma Startup de tecnologia, buscando um impacto
                social no mercado de segurança digital de dados pessoais e
                comerciais.
              </p>
              <p>
                Somos especializados em biometria facial. Buscamos inovações
                para agregar valor ao que já existe na luta contra fraudes. Com
                time de desenvolvimento qualificado, buscamos sem construir
                interfaces agradáveis, com confiabilidade e segurança.
              </p>
            </TextAboutUs>
            <MissionValuesVisionContainer>
              <TextMission>
                <h1>Missão</h1>
                <p>
                  Inovar, usando a tecnologia para transformar as experiências
                  da sociedade, relacionadas a segurança digital de dados
                  pessoais e jurídicos.
                </p>
                <p>
                  A evolução tecnológica é rotineira em todos os setores
                  comercias, industriais e de serviços. Priorizamos inovar
                  criando produtos objetivos e automatizados
                </p>
              </TextMission>
              <TextVision>
                <h1>Visão</h1>
                <p>Trabalhar, discutir e construir juntos. </p>
                <p>
                  Uma busca unificada no objetivo de construir produtos pensando
                  como cliente. Uma visão de equipes dinâmicas e autônomas,
                  usando Metodologia Ágil.
                </p>
              </TextVision>
              <TextValue>
                <h1>Valores</h1>
                <p>Buscar as metas sim, mas sempre com qualidade. </p>
                <p>
                  As dores do cliente precisam ser sanadas, através de nossos
                  produtos. com respeito aos futuros usuários e a LGPD (Lei
                  Geral de Proteção de Dados).
                </p>
              </TextValue>
            </MissionValuesVisionContainer>
          </TextContainer>
        ) : (
          <div className="accordionContainer">
            <Accordion
              style={{ color: "#135070" }}
              defaultExpanded
              // expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
            >
              <AccordionSummary
                style={{
                  backgroundColor: "#135070",
                  color: "#fff",
                  borderBottom: "1px solid rgba(255,255,255,0.7)",
                  borderRadius: "5px 5px 0px 0px",
                }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
              >
                <Typography className="titleAccordion">Quem somos</Typography>
                <Typography sx={{ color: "text.secondary" }}>
                  {/* Nossa direção estratégica e propósito fundamental. */}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  A Sudarium é uma Startup de tecnologia, buscando um impacto
                  social no mercado de segurança digital de dados pessoais e
                  comerciais.<br></br>
                  Somos especializados em biometria facial. Buscamos inovações
                  para agregar valor ao que já existe na luta contra fraudes.
                  Com time de desenvolvimento qualificado, buscamos sem
                  construir interfaces agradáveis, com confiabilidade e
                  segurança.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{ color: "#135070" }}
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
            >
              <AccordionSummary
                style={{
                  backgroundColor: "#135070",
                  color: "#fff",
                  borderBottom: "1px solid rgba(255,255,255,0.7)",
                }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel2bh-header"
              >
                <Typography
                  sx={{ width: "33%", flexShrink: 0 }}
                  className="titleAccordion"
                >
                  Missão
                </Typography>
                <Typography sx={{ color: "text.secondary" }}>
                  {/* Nossa direção estratégica e propósito fundamental. */}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Inovar, usando a tecnologia para transformar as experiências
                  da sociedade, relacionadas a segurança digital de dados
                  pessoais e jurídicos.<br></br>A evolução tecnológica é
                  rotineira em todos os setores comercias, industriais e de
                  serviços. Priorizamos inovar criando produtos objetivos e
                  automatizados
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{ color: "#135070" }}
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3")}
            >
              <AccordionSummary
                style={{
                  backgroundColor: "#135070",
                  color: "#fff",
                  borderBottom: "1px solid rgba(255,255,255,0.7)",
                }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel3bh-header"
              >
                <Typography
                  sx={{ width: "33%", flexShrink: 0 }}
                  className="titleAccordion"
                >
                  Visão
                </Typography>
                <Typography sx={{ color: "text.secondary" }}>
                  {/* Nosso objetivo futuro e aspiração máxima. */}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Trabalhar, discutir e construir juntos.<br></br>
                  Uma busca unificada no objetivo de construir produtos pensando
                  como cliente. Uma visão de equipes dinâmicas e autônomas,
                  usando Metodologia Ágil.
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Accordion
              style={{ color: "#135070" }}
              expanded={expanded === "panel4"}
              onChange={handleChange("panel4")}
            >
              <AccordionSummary
                style={{
                  backgroundColor: "#135070",
                  color: "#fff",
                  borderBottom: "1px solid rgba(255,255,255,0.7)",
                  borderRadius: "0px 0px 5px 5px",
                }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3bh-content"
                id="panel4bh-header"
              >
                <Typography
                  sx={{ width: "33%", flexShrink: 0 }}
                  className="titleAccordion"
                >
                  Valores
                </Typography>
                <Typography sx={{ color: "text.secondary" }}>
                  {/* Princípios que norteiam nossas ações diárias. */}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  Buscar as metas sim, mas sempre com qualidade.<br></br>
                  As dores do cliente precisam ser sanadas, através de nossos
                  produtos. com respeito aos futuros usuários e a LGPD (Lei
                  Geral de Proteção de Dados).
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>
        )}
        <WhatsappButton />
      </Section>
      <Footer />
    </Main>
  );
};
