import { Paths } from "./Routes/index";
// import {Login} from "../src/Pages/Login"
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import style from "./style.module.css";


function App() {
  return (
    <div className={style.app}>
      <Paths />
      <ToastContainer/>
      {/* <Login /> */}
    </div>
  );
}

export default App;
