import styled from "styled-components";

export const Main = styled.div`
  height: 90vh;

  .styledVideo {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: -1;
    transition: fill .3s ease;
  }

  video {
    /* margin-top: 10vh; */
    width: 100%;
    height: 100vh;
    object-fit: cover;
  }

  .overlay {
    position: absolute;
    /* top: 10vh; */
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(#ffffffcc, rgba(19,80,112,.9));
  }

  h1 {
    font-size: 22px;
    color: #135070;
    /* color: #232ed1; */
  }
  p {
    font-size: 15px;
    line-height: 18.29px;
    text-align: justify;
  }

  @media (max-width: 425px) {
    .styledVideo {
      top: 2vh;
    }
    p {
      font-size: 14px;
    }
  }
`;

export const ImgContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    align-items: flex-end;
  }
`;

export const AnimationGif = styled.img`
/* display: none; */
  width: auto;
  height: 450px;
  position: absolute;
  transition: fill .3s ease;

  @media (min-width: 1401px) {
    bottom: 10vh;
  }

  @media (min-width: 1200px) and (max-width: 1400px) {
    border-radius: 0;
    width: auto;
    height: 350px;
    bottom: 10vh;
    margin-left: 70px;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    border-radius: 0;
    width: auto;
    height: 320px;
    bottom: 10vh;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    border-radius: 0;
    width: auto;
    height: 280px;
    bottom: 10vh;
  }
  @media (min-width: 481px) and (max-width: 767px) {
    width: auto;
    height: 280px;
    margin: 80px 0 0 20px;
  }
  @media (max-width: 480px) {
    /* bottom: 75px; */
    width: auto;
    height: 230px;
  }
`;

export const CranioImg = styled.img`
display: none;
  width: auto;
  height: 556px;
  position: fixed;
  right: 0;
  mix-blend-mode: multiply;
  transition: fill .3s ease;

  @media (min-width: 768px) and (max-width: 991px) {
    width: 380px;
    height: 480px;
  }

  @media (min-width: 501px) and (max-width: 767px) {
    width: 350px;
    height: 480px;
  }

  @media (max-width: 500px) {
    height: 300px;
    width: auto;
    position: absolute;
  }
  @media (max-width: 380px) {
    opacity: 40%;
  }
`;

export const ChamadaIMG = styled.img`
  width: 50px;
  height: 35px;
  border-radius: 7px;
`;

export const TextContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  @media (min-width: 481px) and (max-width: 670px) {
    align-self: first baseline;
    margin: 150px 0 0 0;
  }

  @media (max-width: 480px) {
    align-self: flex-start;
    margin: 150px 0 0 0;
    max-width: 90%;
  }
`;

export const TextChamadaEscolar = styled.div`
  background-color: rgba(255, 255, 255, 0.6);
  padding: 10px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  width: 380px;
  margin-left: 50px;
  gap: 15px;

  .alignTitle {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 5px;
  }

  @media (max-width: 1024px) {
    gap: 10px;
  }
  @media (max-width: 500px) {
    width: 300px;
    font-weight: 500;
    margin-top: 50px;
  }
`;

export const Section = styled.section`
  display: flex;
  flex-direction: row;
  height: 90vh;
`;
