import styled from "styled-components";

export const Main = styled.div`
  display: flex;
  height: 80%;
  left: 100px;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  gap: 15px;
  position: absolute;
  transition: fill .3s ease;
  z-index: 10;

  a {
    width: 30px;
    height: 30px;
  }

  @media (min-width: 1200px) {
    left: 50px;
    top: 120px;
  }

  @media (min-width: 992px) and (max-width: 1199px) {
    left: 50px;
    top: 120px;
  }
  @media (min-width: 768px) and (max-width: 991px) {
    left: 50px;
    top: 120px;
  }
  @media (min-width: 481px) and (max-width: 767px) {
    left: 30px;
    top: 100px;
  }
  @media (max-width: 480px) {
    left: 30px;
    top: 100px;
  }
`;
export const SocialMedias = styled.img`
  width: 30px;
  height: 30px;
  transition: fill 0.3s ease;

  a:hover {
	fill: #0077b5;
	color: #0077b5;
  }
`;
